import React, { useState, useEffect } from "react";

/* const Countdown = () => {
  const second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24;

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isWebinar: false,
  });

  useEffect(() => {
    const webinarDay = "02/22/2024";
    const countDownDate = new Date(webinarDay).getTime();

    const x = setInterval(function () {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setCountdown({
        days: Math.floor(distance / day),
        hours: Math.floor((distance % day) / hour),
        minutes: Math.floor((distance % hour) / minute),
        seconds: Math.floor((distance % minute) / second),
        isWebinar: distance < 0,
      });

      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);

    return () => clearInterval(x);
  });

  return (
    <>
      {countdown.isWebinar ? (
        <h1>Sorry you are little late for registration, Thanks For Visiting!</h1>
      ) : (
        <>
        <div className="countdown-wrapper">
        <h1>Managing Imposter Syndrome</h1>
        <p>February 22, 2024 | 11:00 a.m. - 12:00 noon IST | Virtual</p>
        <h2>Registration closes in...</h2>
        </div>
        <div id="countdown">
          <div><span id="days">{countdown.days}</span>Days</div>
          <div><span id="hours">{countdown.hours}</span>Hours</div>
          <div><span id="minutes">{countdown.minutes}</span>Minutes</div>
          <div><span id="seconds">{countdown.seconds}</span>Seconds</div>
        </div>
        </>
      )}
    </>
  );
}; */


const Countdown = () => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isWebinar: false,
  });

  const getWebinarDate = () => {
    // Use a conditional check to provide flexibility in setting the webinar date
    if (process.env.REACT_APP_WEBINAR_DATE) {
      // Use environment variable for production deployments
      return new Date(process.env.REACT_APP_WEBINAR_DATE).getTime();
    } else {
      // Use a static default date for development or fallback
      return new Date('2024-02-22T11:00:00').getTime();
    }
  };

  useEffect(() => {
    const countDownDate = getWebinarDate();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance > 0) {
        setCountdown({
          days: Math.floor(distance / day),
          hours: Math.floor((distance % day) / hour),
          minutes: Math.floor((distance % hour) / minute),
          seconds: Math.floor((distance % minute) / second),
          isWebinar: false,
        });
      } else {
        setCountdown({
          ...countdown,
          isWebinar: true,
        });
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <>
      {countdown.isWebinar ? (
        <h1>
          Sorry you are late for registration. Thanks for your interest in the
          webinar!
        </h1>
      ) : (
        <>
          <div className="countdown-wrapper">
            <h1>Managing Imposter Syndrome</h1>
            <p>
              February 22, 2024 | 11:00 a.m. - 12:00 noon IST | Virtual
            </p>
            <h2>Registration closes in...</h2>
          </div>
          <div id="countdown">
            <div>
              <span id="days">{formatTime(countdown.days)}</span>Days
            </div>
            <div>
              <span id="hours">{formatTime(countdown.hours)}</span>Hours
            </div>
            <div>
              <span id="minutes">{formatTime(countdown.minutes)}</span>Minutes
            </div>
            <div>
              <span id="seconds">{formatTime(countdown.seconds)}</span>Seconds
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Countdown;
