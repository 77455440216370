import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import Pagenotfound from './Pagenotfound';


const AppRouter = () => {
    return (
      <Router>
       
        <Routes>
          <Route exact path="/" element={<MainPage/>} />
          <Route exact path="/webinar/cxo-hacks-managing-imposter-syndrome" element={<MainPage/>} />
          <Route exact path="/webinar" element={<MainPage/>} />

          <Route exact path="/*" element={<Pagenotfound/>} />
          
        </Routes>
       
      </Router>
    );
  };
  
  export default AppRouter;